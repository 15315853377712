<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Rate"
          v-validate="'required'"
          name="Rate"
          v-model="data.rate"
          @keypress="isNumber($event)"
        />
        <span class="text-danger text-sm" v-show="errors.has('Rate')">{{
            errors.first("Rate")
          }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Default</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.isDefault"
        >
          <span slot="on">Yes</span>
          <span slot="off">No</span>
        </vs-switch>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Class"
          name="Class"
          v-validate="'numeric|min_value:1'"
          v-model="data.class"
        />
        <span class="text-danger text-sm" v-show="errors.has('Class')">{{
            errors.first("Class")
          }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Tax Report</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.isTaxReport"
        >
          <span slot="on">Yes</span>
          <span slot="off">No</span>
        </vs-switch>
      </div>
    </div>

    
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Tax Invoice</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.isTaxInvoice"
        >
          <span slot="on">Yes</span>
          <span slot="off">No</span>
        </vs-switch>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Tax Group</label>
        <multiselect
          v-model="data.taxGroup.selected"
          :options="data.taxGroup.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option }}</span>
        </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Tax Group')">{{
            errors.first("Tax Group")
          }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Coa Code</label>
        <multiselect
          v-model="data.coaCode.selected"
          :options="data.coaCode.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
          track-by="ID"
          :custom-label="customLabelCoa"
        >
          <span slot="noResult">Oops! No data found</span>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Coa Code')">{{
            errors.first("Coa Code")
          }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
        >Close
        </vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    isDefault: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          rate: 0,
          isDefault: 0,
          class: null,
          isTaxReport: false,
          isTaxInvoice: true,
          taxGroup: {
            options: ["VAT", "WHT"],
            selected: "",
          },
          coaCode: {
            options: [],
            selected: null
          }
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (this.data.rate < 0 || this.data.rate > 100) {
          this.errors.add({
            field: "Rate",
            msg: "The Rate field is required 0-100",
          });
          result = false;
        }

        if (result) {
          if (this.data.isDefault && this.isDefault != 0) {
            this.$vs.dialog({
              type: "confirm",
              color: "danger",
              title: `Confirm`,
              text: "Another tax is set to default, do you want to continue?",
              accept: this.acceptDefault,
            });
          } else {
            this.$vs.loading();
            if (this.id) {
              this.putData();
            } else {
              this.postData();
            }
          }
        }
      });
    },
    acceptDefault() {
      this.$vs.loading();
      if (this.id) {
        this.putData();
      } else {
        this.postData();
      }
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        name: this.data.name,
        rate: parseFloat(this.data.rate),
        is_default: this.data.isDefault ? 1 : 0,
        is_tax_report: this.data.isTaxReport,
        generate_tax_invoice: this.data.isTaxInvoice,
        class: this.data.class,
        tax_group: this.data.taxGroup.selected,
        coa_id: this.data.coaCode.selected.ID,
        coa_code: this.data.coaCode.selected.Code,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.ID = resp.data.ID;
            this.data.name = resp.data.Name;
            this.data.rate = resp.data.Rate;
            this.data.isDefault = resp.data.IsDefault == 1 ? true : false;
            this.data.isTaxReport = resp.data.IsTaxReport;
            this.data.isTaxInvoice = resp.data.GenerateTaxInvoice;
            this.data.class = resp.data.Class;
            this.data.taxGroup.selected = resp.data.TaxGroup;
            this.data.coaCode.selected = this.data.coaCode.options.find(option => option.ID === resp.data.CoaCodeID);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getCoaData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-accounts", {})
        .then((resp) => {
          if (resp.code === 200) {
            this.data.coaCode.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLabelCoa({ Code, Name }) {
      return `${Code} - ${Name}`;
    },
  },
  mounted() {
    this.getCoaData();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style>
</style>
